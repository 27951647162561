<template>
  <div>
    <div v-if="!isLoading" class="box box-default">
      <div class="box-header">
        <div class="flex flex-col-sm gap-8 align-items-center justify-between">
          <h3 class="box-title">Scheduled</h3>
          <div class="flex gap-8 align-items-center">
            <span class="text-bold text-danger">{{ searching }}</span>
            <input v-model="keyword" aria-label="search" class="form-control" placeholder="Search..." type="text"
              @keyup="search()" />
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-condensed">
            <thead>
              <tr>
                <th>ID</th>
                <th>Merchant</th>
                <th>AltCoin</th>
                <th>InputCurrency</th>
                <th>CustomerReferenceNr</th>
                <th>Comments</th>
                <th>Schedule</th>
                <th>Type</th>
                <th>Disabled</th>
                <th>EmailReceiver</th>
                <th>LastExecutedOn</th>
                <th>CreatedOn</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-if="records.length > 0">
                <tr v-for="(record, index) in records" :key="index">
                  <td>{{ record.ID }}</td>
                  <td>{{ record.OwnerNickName }}</td>
                  <td>{{ `${record.CoinName} (${record.LongName})` }}</td>
                  <td>{{ record.InputCurrencyLongName }}</td>
                  <td style="max-width: 300px;word-break: break-all">{{ record.CustomerReferenceNr }}</td>
                  <td>{{ record.Comments }}</td>
                  <td>{{ record.Schedule }}</td>
                  <td>{{ record.Type }}</td>
                  <td><span v-html="formatDisabled(record.Disabled)"
                      @click="toggleTransactionSchedulingBoolFields(record, 'Disabled')"></span></td>
                  <td>{{ record.EmailReceiver }}</td>
                  <td>{{ record.LastExecutedOn }}</td>
                  <td>{{ record.CreatedOn }}</td>
                  <td></td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="13">No record found!</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { mapGetters } from "vuex";
import { START_LOADING, STOP_LOADING } from "@/store/keys";
import { logout, saveNotification } from "@/lib/utils";

export default {
  name: "TransactionScheduling",
  components: {
    Pagination,
  },
  data() {
    return {
      records: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      keyword: "",
      searching: "",
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "currentUser",
    ]),
  },
  methods: {
    updatePage(page) {
      this.getPaginatedRecords(page);
    },
    search() {
      this.searching = "Searching...";
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.getPaginatedRecords(1);
      }, 500);
    },
    getPaginatedRecords(page = 1) {
      this.$store.commit(START_LOADING);
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMisc&type=transaction_scheduling`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword: this.keyword ? this.keyword.trim() : "",
          limit: Number.parseInt(this.meta.limit),
        },
      }).then((response) => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        if (response.status === 200) {
          this.records = response.data.data.records;
          this.meta = response.data.data.meta;
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    formatDisabled(val) {
      const label = val ? "Yes" : "No";
      const lblClass = val ? "label-danger" : "label-success";
      return `<label class="label ${lblClass}">${label}</label>`;
    },
    toggleTransactionSchedulingBoolFields(record, key) {
      const index = this.records.indexOf(record);
      const payload = {
        table: "Transaction_Scheduling",
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        ID: record.ID,
        field: key,
        value: Number(record[key]) === 0 ? 1 : 0,
        output: "json",
      };
      const url = process.env.VUE_APP_API_URL;
      this.$http.post(`${url}/v2REAPI?Call=UpdateTable`, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        if (response.data && response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        } else {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
          record[key] = payload.value;
          this.records.splice(index, 1, record);
        }
      }).catch(error => {
        console.log(error);
      });
    },
  },
  mounted() {
    this.getPaginatedRecords();
  },
  beforeDestroy() {
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style scoped></style>
